.validation-message {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-small);
  width: 100%;

  &.success {
    color: var(--color-text-secondary);
  }

  &.error {
    color: var(--color-error-light);
  }

  .icon {
    display: flex;
    fill: currentColor;
    flex-grow: 0;
    flex-shrink: 0;
    height: 1em;
    margin-right: 0.25rem;
    width: 1em;
  }
}
